import { responsiveTrigger, ScrollTrigger } from '../gsap';
import React, { useEffect, useMemo, useState } from 'react'
import { ParentSize } from '@visx/responsive';
import UkMap from './UkMap';
import { ChartSection, Commentary, ScrollyTeller } from '../ScrollyTeller';

const current = { stage: 0 }

export default function SmartCities({ chart, paragraphs }) {
    const [stage, setStage] = useState("")

    useEffect(() => {

        responsiveTrigger({
            obj: {
                trigger: "#map-0",
                endTrigger: "#map-1",

                onToggle: ({ isActive }) => {
                    if (isActive) {
                        setStage("leaders")
                    }
                }
            },
            desktop: {
                start: "center center",
                end: "top center",
            },
            mobile: {
                start: "center bottom",
                end: "center bottom",
            }
        });

        responsiveTrigger({
            obj: {
                trigger: "#map-1",
                endTrigger: "#map-2",
                onToggle: ({ isActive }) => {
                    if (isActive) {
                        setStage("developing")
                    }
                }
            },
            desktop: {
                start: "center center",
                end: "top center",
            },
            mobile: {
                start: "center bottom",
                end: "center bottom",
            }
        });

        responsiveTrigger({
            obj: {
                trigger: "#map-2",
                
                onToggle: ({ isActive }) => {
                    if (isActive) {
                        setStage("emerging")
                    }
                }
            },
            desktop: {
                start: "top center",
                end: "bottom center-=100px",
            },
            mobile: {
                start: "center bottom",
                end: "center center",
            }
        })

    }, [])


    return (
        <ScrollyTeller>
            <ChartSection>
                <ParentSize>
                    {({ width, height, top, left, ref }) => (
                        <UkMap
                            width={width}
                            height={height}
                            top={top}
                            left={left}
                            ref={ref}
                            cities={chart.data[stage]}
                            features={chart.features}
                            stage={stage}
                        />
                    )}
                </ParentSize>
                <span dangerouslySetInnerHTML={{ __html: chart.source }} />
            </ChartSection>
            <Commentary>
                {paragraphs.map((item, idx) => (
                    <p id={"map-" + idx}>{item}</p>
                ))}
            </Commentary>

        </ScrollyTeller>
    )
}