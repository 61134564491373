import React from 'react'
import { css, jsx } from '@emotion/core'

const ScrollyStyles = css`
    min-height: 70vh;
    padding: 1rem;
    display: flex;
    justify-content: center;
    margin: 2rem 0;
    > div:first-of-type {
        height: 70vh;
        max-height: 650px;
        width: 65%;
        max-width: 850px;
        background: #fff;
        position: sticky;
        top: calc(50vh - 35vh) ;
        padding: 10px;
        box-shadow: 20px 20px 30px rgb(0 0 0 / 10%);
        > span {
            position: absolute;
            bottom: 8px;
            font-size: 12px;
            right: 8px;
        }
    }
    > div:last-of-type {
        margin-left: 2rem;
        > p {
            max-width: 400px;
            border-radius: 5px;
            padding: 2rem;
            margin-top: 50vh;
            margin-bottom: 50vh;
            font-size: 20px;
            line-height: 1.4;
        }
    }
    @media(max-width: 1200px) {
        width: 100%;
        padding: 0;
        > div:first-of-type {
            width: 70%;
        }
    }

    @media(max-width: 900px){
        > div:first-of-type {
            width: 100%;
            top: 0;
            height: 60vh;
            padding: 0;
            max-height: auto;
        }
        flex-direction: column;
        > div:last-of-type {
            margin-left: 0;
            > p {
                max-width: 400px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
`
export function ScrollyTeller({ children }) {
    return (
        <div css={ScrollyStyles}>
            {children}
        </div>
    )
}


export function ChartSection({ children, ...rest}) {
    return (
        <div {...rest}>
            {children}
        </div>
    )
}

export function Commentary({ children }) {
    return (
        <div>
            {children}
        </div>
    )
}