export default [
    {
        "2014": 1.4,
        "2015": 2.6,
        "2016": 2.6,
        "2017": 4.6,
        "2018": 4,
        "2019": 6.8,
        "city": "London"
    },
    {
        "2014": 1.6,
        "2015": 1.8,
        "2016": 0.9,
        "2017": 1.6,
        "2018": 1.9,
        "2019": 3.5,
        "city": "Berlin"
    },
    {
        "2014": 0.5,
        "2015": 0.9,
        "2016": 1,
        "2017": 1.3,
        "2018": 1.9,
        "2019": 2.4,
        "city": "Paris"
    },
    {
        "2014": 0.2,
        "2015": 0.2,
        "2016": 0.4,
        "2017": 0.4,
        "2018": 0.5,
        "2019": 1.3,
        "city": "Stockholm"
    },
    {
        "2014": 0.2,
        "2015": 0.2,
        "2016": 0.2,
        "2017": 0.2,
        "2018": 0.4,
        "2019": 1.1,
        "city": "Munich"
    },
    {
        "2014": 0,
        "2015": 0,
        "2016": 0,
        "2017": 0,
        "2018": 0.3,
        "2019": 0.6,
        "city": "Bucharest"
    },
    {
        "2014": 0.2,
        "2015": 0.3,
        "2016": 0.3,
        "2017": 0.4,
        "2018": 0.7,
        "2019": 0.6,
        "city": "Barcelona"
    },
    {
        "2014": 0.4,
        "2015": 0.2,
        "2016": 0.2,
        "2017": 0.4,
        "2018": 0.3,
        "2019": 0.6,
        "city": "Amsterdam"
    },
    {
        "2014": 0,
        "2015": 0.1,
        "2016": 0.2,
        "2017": 0.2,
        "2018": 0.4,
        "2019": 0.5,
        "city": "Cambridge"
    },
    {
        "2014": 0.1,
        "2015": 0.1,
        "2016": 0.2,
        "2017": 0.3,
        "2018": 0.3,
        "2019": 0.5,
        "city": "Madrid"
    },
    {
        "2014": 0,
        "2015": 0,
        "2016": 0,
        "2017": 0.5,
        "2018": 0.2,
        "2019": 0.4,
        "city": "Zurich"
    },
    {
        "2014": 0.1,
        "2015": 0,
        "2016": 0.1,
        "2017": 0.1,
        "2018": 0.2,
        "2019": 0.3,
        "city": "Bristol"
    },
    {
        "2014": 0.1,
        "2015": 0.1,
        "2016": 0.1,
        "2017": 0.1,
        "2018": 0.3,
        "2019": 0.3,
        "city": "Helsinki"
    },
    {
        "2014": 0.2,
        "2015": 0.1,
        "2016": 0.6,
        "2017": 0.2,
        "2018": 0.3,
        "2019": 0.3,
        "city": "Dublin"
    },
    {
        "2014": 0,
        "2015": 0.1,
        "2016": 0,
        "2017": 0,
        "2018": 0.1,
        "2019": 0.2,
        "city": "Utrecht"
    },
    {
        "2014": 0.1,
        "2015": 0.1,
        "2016": 0.2,
        "2017": 0.2,
        "2018": 0,
        "2019": 0.2,
        "city": "Manchester"
    },
    {
        "2014": 0,
        "2015": 0.1,
        "2016": 0.2,
        "2017": 0.1,
        "2018": 0.4,
        "2019": 0.2,
        "city": "Oxford"
    },
    {
        "2014": 0.1,
        "2015": 0.1,
        "2016": 0.2,
        "2017": 0.3,
        "2018": 0.4,
        "2019": 0.1,
        "city": "Hamburg"
    },
    {
        "2014": 0,
        "2015": 0,
        "2016": 0,
        "2017": 0,
        "2018": 0.1,
        "2019": 0.1,
        "city": "Vilnius"
    },
    {
        "2014": 0,
        "2015": 0.1,
        "2016": 0,
        "2017": 0.1,
        "2018": 0.1,
        "2019": 0.1,
        "city": "Copenhagen"
    }
]