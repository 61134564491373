import { css } from '@emotion/core'
import React from 'react'


const styling = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 864px;
    margin: 4rem 0;
    margin-left: 75px;
    padding: 2rem;
   
    > p {
        max-width: 832px;
        font-size: 20px;
        color: #121212;
        margin: 8px 0;
        line-height: 1.4;
       
    }
    @media(max-width: 1272px) {
    }
    @media (max-width: 1100px) {

            margin-left: 75px;

        
    }
    @media (max-width: 900px) {

            margin-left: 0rem;

   
        margin-top: 150px;
  
        
    }
    @media(max-width: 600px) {
        margin-top: 90px;
        
    }

`
export default function ParagraphSection({ paragraphs, ...rest }) {
    return (
        <div css={styling}>
            {
                paragraphs.map(p =>
                    (<p dangerouslySetInnerHTML={{ __html: p }} {...rest}></p>)
                )
            }
        </div>
    )
}
