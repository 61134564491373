import { css } from '@emotion/core'
import React from 'react'



const HeaderStyles = css`
    display: flex;
    height: 150px;
    width: 100%;
    background: transparent linear-gradient(101deg, #3EC3CE 0%, #13214F 100%) 0% 0% no-repeat padding-box;
    align-items: center;
    padding: 2rem;
    padding-left: 150px;
    justify-content: space-between;
    margin: 1rem 0;
    > div > h2 {
        font-size: 46px;
        font-family: EconSansOSLight;
        color: #fff;
        font-weight: 300;
    }
    > div > p {
        max-width: 1088px;
        font-size: 20px;
        color: #fff;
        margin-top: 1rem;
        line-height: 1.4;
        a {
            color: #fff;
        }
    }

    img {
        max-height: 300px;
        max-width: 500px;
    }

    @media(max-width: 1272px) {
        img {
            max-height: 250px;
        }
    }
    @media (max-width: 1100px) {
        padding-left: 75px;
    }
    @media (max-width: 900px) {
        padding-left: 2rem;
        flex-direction: column;
        height: 120px;
        > img {
            align-self: flex-end;
        }
        > div {

            width: 100%;
        }
        h2 {
            width: 100%;
        }
    }
    @media(max-width: 600px) {
        > img {
            max-height: 150px;
        }
        
    }
`

export default function HeaderSection({ title, copy, img, extend }) {
    const extended = css`
        height: auto !important; 
        min-height: 150px;
    `
    return (
        <div css={[HeaderStyles, extend && extended].filter(x => x)}>
            <div>
                <h2>{title}</h2>
                <p dangerouslySetInnerHTML={{__html: copy}}></p>
            </div>
            <img {...img}>
            </img>
        </div>
    )
}
