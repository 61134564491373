import React, { useEffect, useMemo, useState } from 'react'
import { Bar } from '@visx/shape';
import { ParentSize } from '@visx/responsive';
import { Group } from '@visx/group';
import { ChartSection, Commentary, ScrollyTeller } from '../ScrollyTeller';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { LegendOrdinal } from '@visx/legend';
import { css } from '@emotion/core';
import { motion } from "framer-motion"
import { responsiveTrigger, ScrollTrigger } from '../gsap';
import { useTooltip, Tooltip, defaultStyles } from '@visx/tooltip';
import { Annotation, Connector, CircleSubject, Label } from '@visx/annotation';
import { ResponsiveBubble } from '@nivo/circle-packing'



const margin = { top: 40, left: 80, right: 40, bottom: 40 };


export function BubbleChart({ width, height, data, stage }) {
    const format = (stage) => {
        if (stage === 0) {
            return d => `${d.id}: $${d.value}B`
        }else {
            return d => `${d.id}: $${d.value}M`
        }
    }

    const formatTooltip = (stage) => {
        if (stage === 0) {
            return d => `$${d}B`
        }else {
            return d => `$${d}M`
        }
    }
    return (

        <ResponsiveBubble
            root={data}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            identity="name"
            value="loc"
            colors={["#3EC3CE", "#3D88C2", "#FCDFCC", "#F5AA44", "#F57756", "#6ba822"]}
            padding={6}
            labelTextColor={'#121212'}
            borderWidth={2}
            borderColor={{ from: 'color' }}
            colorBy="name"
            animate={true}
            motionStiffness={90}
            motionDamping={12}
            leavesOnly={true}
            label={format(stage)}
            tooltipFormat={formatTooltip(stage)}
            theme={{
                fontSize: 16
            }}
            labelSkipRadius={50}
           
        />
    )
}





export default function Bubble({ chart, paragraphs }) {
    const [stage, setStage] = useState(0)
    useEffect(() => {
        responsiveTrigger({
            obj: {
                trigger: "#bubble-0",
                endTrigger: "#bubble-1",

                onToggle: ({ isActive, direction }) => {
                    if (isActive) {
                        setStage(0)
                    }

                }
            },
            desktop: {
                start: "top center",
                end: "top center",
            },
            mobile: {
                start: "center bottom",
                end: "center bottom",
            }
        });

        responsiveTrigger({
            obj: {
                trigger: "#bubble-1",
         
                onToggle: ({ isActive, direction }) => {
    
                    if (isActive) {
                        setStage(1)
                    }
                }
            },
            desktop: {
                start: "center center",
                end: "top top",
            },
            mobile: {
                start: "center bottom",
                end: "bottom center",
            }
        })

    }, [])
    return (
        <ScrollyTeller>
            <ChartSection>
                <BubbleChart
                    data={chart.data[stage]}
                    stage={stage}
                />
                <span dangerouslySetInnerHTML={{ __html: chart.source }} />
            </ChartSection>
            <Commentary>
                {paragraphs.map((item, idx) => (
                    <p id={"bubble-" + idx}>{item}</p>
                ))}
            </Commentary>

        </ScrollyTeller>
    )
}