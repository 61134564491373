export default {
    "leaders": [
        "glasgow",
        "edinburgh",
        "manchester",
        "bristol",
        "oxford",
        "london"
    ],
    "developing": [
        "newcastle",
        "leeds",
        "nottingham",
        "peterborough",
        "birmingham",
        "cambridge",
        "milton keynes",
        "brighton"
    ],
    "emerging": [
        "aberdeen",
        "dundee",
        "belfast",
        "liverpool",
        "sheffield",
        "cardiff",
        "reading",
        "exeter",
        "bournemouth"
    ]
}