import React from 'react'
import { css } from '@emotion/core'
import Layout from '../../components/Layout/Layout'
import LeadsInvestment from '../../components/DataStory/chart1/LeadsInvestmentBar'
import IotBar from '../../components/DataStory/chart2/UkIotBar'
import DataStoryContent from '../../constants/pages/datastory'
import UkCyberExportsBar from '../../components/DataStory/chart3/UkCyberExportsBar'
import GasReductionArea from '../../components/DataStory/chart6/GasReductionArea'
import SmartCities from '../../components/DataStory/chart5/SmartCities'
import HeroSecondSection from '../../components/Sections/HeroSecondSection/HeroSecondSection';
import UtilBranLock from '@economist/fabric-components/utility/utilbranlock/UtilBranLock';
import { withPrefix } from 'gatsby'
import HeaderSection from '../../components/DataStory/HeaderSection'
import SliderSection from '../../components/Sections/SliderSection/SliderSection'
import FintechSubsectors from '../../components/DataStory/chart8/FintechSubsectors'
import Sankey from '../../components/DataStory/chart4/Sankey'
import Bubble from '../../components/DataStory/chart7/Bubble'
import ParagraphSection from '../../components/DataStory/ParagraphSection'
import EdtechLine from '../../components/DataStory/chart9/EdtechLine'

import ScrollDepth from '@economist/fabric-components/utility/ScrollDepthTracker/ScrollDepth';
import UtilWebVitals from '@economist/fabric-components/utility/utilwebvitals/UtilWebVitals';
import {Page} from '../../components/Page';
import Seo from "../../components/Seo/Seo";
// ideally we want the colors to be sourced from one place
const datastory = css`
    background: linear-gradient(90deg, #FFF 50%, #eeeded 50%);
    padding: 1rem 0;
    > div:first-of-type {
        padding: 0 2rem;
    }
    @media(max-width: 600px) {
        > div:first-of-type {
            padding: 0 1rem;
        }
    }
`



export default function DataStory() {
    return (
        <Page>
            <Seo title={"Data Story | Department for International Trade"}  image={"https://futuretradeandinvestment.economist.com/images/HomePage_HeroImage_compressed.jpg"}/>
            <Layout>
                <UtilWebVitals />
                <ScrollDepth />
                <div css={datastory}>
                    <HeroSecondSection
                        fullScreen={true}
                        authorComponent={
                            <UtilBranLock
                                alt="Innovation is great"
                                text=""
                                logo={withPrefix('/images/innovation-is-great.png')}
                            />}
                        ebcContent={
                            <UtilBranLock
                                alt="EBrandConnect"
                                text="Produced by"
                                logo={withPrefix('/images/EBCONPRGB.svg')}
                                align={"center"}
                            />

                        }
                        position="left"
                        data={{
                            banner: {
                                firstImg: '/images/Hero_desktop_optimized.jpg',
                                secondImg: '/images/fullwidth-placeholder.jpg'
                            },
                            main: {
                                title: DataStoryContent.intro.title,
                                content: DataStoryContent.intro.copy
                            },
                            file: {
                                href: ""
                            }
                        }}
                    />
                    {DataStoryContent.main.map((item) => (getComponent(item)))}
                    <SliderSection
                        scale={.2}
                        headerImgPath={DataStoryContent.sliderSection.headerImgPath || false}
                        headerImgAlt={DataStoryContent.sliderSection.headerImgAlt || false}
                        settings={DataStoryContent.sliderSection.settings}
                        items={DataStoryContent.sliderSection.items}
                    />
                </div>
            </Layout>
        </Page>
    )
}


const getComponent = (object) => {
    switch (object.type) {
        case "header": {

            return <HeaderSection {...object} />
        }
        case "paragraphs": {
            return <ParagraphSection {...object} />
        }
        case "scrollyteller": {
            return getScrollyTellerChart(object)
        }
        default:
            return
    }
}



const getScrollyTellerChart = (object) => {
    switch (object.chart.type) {
        case "bar-stacked": {
            return <LeadsInvestment {...object} />
        }
        case "bar-horizontal": {
            return <IotBar  {...object} />
        }
        case "bar-vertical": {
            return <UkCyberExportsBar  {...object} />
        }
        case "area": {
            return <GasReductionArea {...object} />
        }
        case "map": {
            return <SmartCities {...object} />
        }
        case "lollipop": {
            return <FintechSubsectors {...object} />
        }
        case "sankey": {
            return <Sankey {...object} />
        }
        case "bubble": {
            return <Bubble {...object} />
        }
        case "line": {
            return <EdtechLine {...object} />
        }
    }
}