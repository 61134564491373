import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react'

import { ChartSection, Commentary, ScrollyTeller } from '../ScrollyTeller';

import { css } from '@emotion/core';

import { responsiveTrigger, ScrollTrigger } from '../gsap';

import { ResponsiveLine } from '@nivo/line'
import { patternLinesDef } from '@nivo/core'



const margin = { top: 40, left: 80, right: 40, bottom: 40 };



const getYear = (d) => d.year

export function LineChart({ data, highlighted }) {

    return (
        <ResponsiveLine
            data={data}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 0, max: 400, stacked: false, reverse: false }}
            axisTop={null}
            axisRight={null}
            yFormat={">-$.2~f"}
            colors={["#3EC3CE", "#3D88C2", "#C32F22", "#482621"]}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Year (* through Sept 2020)',
                legendOffset: 36,
                legendPosition: 'middle'
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'VC investment in EdTech by country ($m)',
                legendOffset: -40,
                legendPosition: 'middle'
            }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            enableGridX={false}
    
            enableCrosshair={true}
            useMesh={true}
            defs={[

                patternLinesDef('lines-pattern', { color: 'inherit' }),

            ]}
            fill={[
                { match: d => highlighted.includes(d.id) , id: 'lines-pattern' }
            ]}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />

    )
}


const current = { stage: 0 }
let currentYear = 2018


const generateData = (year, data) => {
   
    const newData = []
    for (let item of data) {
        newData.push({
            ...item,
            data: item.data.filter(x => x.x <= year)
        })
    }

    return newData
}

export default function EdtechLine({ chart, paragraphs }) {
    const [year, setYear] = useState(currentYear)
    const [highlighted, setHighlighted] = useState([""])
    useEffect(() => {
        responsiveTrigger({
            obj: {
                trigger: "#line-0",
                endTrigger: "#line-1",
                onToggle: ({ isActive }) => {
                    if (isActive) {
                        setYear(2019)
                    }
                }
                
            },
            desktop: {
                start: "top center",
                end: "top center"
            },
            mobile: {
                start: "center bottom",
                end: "center bottom"
            }
        });
        responsiveTrigger({
            obj: {
                trigger: "#line-1",
                endTrigger: "#line-1",
                onToggle: ({ isActive }) => {
                    if (isActive) {
                        setYear("2021")
                    }
                }
                
            },
            desktop: {
                start: "top center",
                end: "top top"
            },
            mobile: {
                start: "center bottom",
                end: "center center"
            }
        });
    }, [])
    return (
        <ScrollyTeller>
            <ChartSection>
                <LineChart
                    data={generateData(year, chart.data)}
                    highlighted={highlighted}
                />
                <span dangerouslySetInnerHTML={{ __html: chart.source }} />
            </ChartSection>
            <Commentary>
                {paragraphs.map((item, idx) => (
                    <p id={"line-" + idx}>{item}</p>
                ))}
            </Commentary>

        </ScrollyTeller >
    )
}


function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();

    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
}