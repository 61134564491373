export default [
    {
        "year": 2016,
        "devices": "13000000"
    },
    {
        "year": "2024",
        "devices": "150000000"
    },
    // {
    //     "year": "2024P",
    //     "devices": "120 million"
    // }
]