export default [
    {
        key: "Baseline",
        data: [
            {
                year: 2015,
                value: 96167
            },
            {
                year: 2020,
                value: 89804
            },
            {
                year: 2025,
                value: 83630
            },
            {
                year: 2030,
                value: 79142
            },
            {
                year: 2035,
                value: 77148
            },
            {
                year: 2040,
                value: 76307
            },
            {
                year: 2045,
                value: 75528
            },
            {
                year: 2050,
                value: 75150
            }

        ]
    },
    {
        key: "EV",
        data: [
        
            {
                year: 2015,
                value: 96167
            },
            {
                year: 2020,
                value: 89528
            },
            {
                year: 2025,
                value: 80656
            },
            {
                year: 2030,
                value: 67907
            },
            {
                year: 2035,
                value: 49650
            },
            {
                year: 2040,
                value: 32569
            },
            {
                year: 2045,
                value: 22329
            },
            {
                year: 2050,
                value: 19062
            }]
    }
]