import React, { useState } from 'react'
import { Mercator, Graticule } from '@visx/geo';
import { Annotation, CircleSubject, Connector, Label } from '@visx/annotation';
import { motion } from "framer-motion"



const colors = ["#3EC3CE", "#3D88C2", "#FCDFCC", "#F5AA44", "#C32F22", "#482621"]


function UkMap({ width, height, background = "#fff", events = true, features, cities, subtitle, stage}) {

   
    const defaultCenter = [1.5197, 55.4068]
    const centerX = width / 2;
    const centerY = height / 2;
    const scale =  height < 600 ? height/35 * 100 : (width / 45) * 100;
    const shiftX = width < 600 ? 90 : 100
    const translate = [centerX + shiftX, centerY - 50 ]
    return (
        <svg width={width} height={height} >
            <rect x={0} y={0} width={width} height={height} fill={background} rx={14} />
            <Mercator
                data={features}
                scale={scale}
                center={defaultCenter}
                translate={translate}
            >
                {mercator => (
                    <g>

                        <Graticule graticule={g => mercator.path(g) || ''} stroke="rgba(33,33,33,0.05)" />

                        {mercator.features.map(({ feature, path }, i) => (
                            <path
                                key={`map-feature-${i}`}
                                d={path || ''}
                                fill={fill(feature, stage, cities)}
                                stroke={background}
                                strokeWidth={1}

                                onClick={() => {
                                    if (events) console.log(`Clicked: ${feature.properties['NAME_2']} (${feature.properties.ID_2})`);
                                }}
                            />

                        ))}
                        {mercator.features.map(({ feature, centroid }, i) => (
                            <>  
                          
                                {cities && cities.includes(feature.properties.NAME_2.toLowerCase()) &&
                                    <motion.g
                                    initial={{
                                        opacity: 0
                                    }}
                                    animate={{
                                        opacity: 1
                                    }}
                                    transition={{ duration: 0.5 }}
                                    >
                                        <Annotation
                                            x={centroid[0]}
                                            y={centroid[1]}
                                            dx={cities.findIndex(z =>  z === feature.properties.NAME_2.toLowerCase()) % 2 == 0 ? - (shiftX + (centroid[0] - width/2) ) : (shiftX - (centroid[0] - width/2)) }
                                            dy={cities.findIndex(z  => z === feature.properties.NAME_2.toLowerCase()) % 3 == 0 ? -50 : 50 }
                                            key={`map-annotation-${i}`}

                                        >
                                            <Connector />
                             
                                            <CircleSubject radius={1} />
                                            <Label
                                                title={feature.properties.NAME_2}
                                                backgroundFill={"rgba(255,255,255,1)"}
                                                subtitle={subtitle}
                                                anchorLineStroke={colorFromStage(stage)}

                                            />
                                        </Annotation>
                                    </motion.g>
                                }
                            </>
                        ))}
                    </g>
                )}
            </Mercator>)
        </svg>
    )
}



const fill = (feature, stage , cities) => {

    if (cities && cities.includes(feature.properties.NAME_2.toLowerCase())) {
        return colorFromStage(stage)

    }
    return "#d1d1d1"
}


const colorFromStage  = (stage) => {
    switch(stage) {
        case "emerging": {
            return "#88A1F7"
        }
        case "leaders": {
            return "#3daef5"
        }
        case "developing": {
            return "#f57756"
        }
    }
}


export default UkMap