export default [
    {
      "year": 2016,
      "value": 1.468
    },
    {
      "year": 2017,
      "value": 1.83
    },
    {
      "year": 2018,
      "value": 2.085
    },
    {
      "year": 2019,
      "value": 3.963
    }
  ]