import React, { useState, useEffect } from 'react'
import { ChartSection, Commentary, ScrollyTeller } from '../ScrollyTeller';
import { ParentSize } from '@visx/responsive';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal, scaleTime } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { LegendOrdinal } from '@visx/legend';
import { css } from '@emotion/core';
import { motion } from "framer-motion"
import { responsiveTrigger, ScrollTrigger } from '../gsap';
import { Area, AreaClosed } from '@visx/shape';
import { curveNatural } from '@visx/curve';
import { ResponsiveLine } from '@nivo/line'
import { patternLinesDef } from '@nivo/core'


const margin = { top: 40, left: 80, right: 0, bottom: 40 };

const BarStyles = css`
    position: relative;
    > div {
        position: absolute;
        top: 0px;
        right: 0;
        height: 128px;
        width: 172px;
        > div {
            height: 100%;
            width: 100%;
            border-left: 1px solid #121212;
            border-bottom: 1px solid #121212;
            padding: 0 0px 8px 8px;
        }
    }
   
`

const getYear = (d) => d.year
const getYearValue = d => d.value
const maxValue = (d) => Math.max(...d.map(getYearValue))


const keys = [
    2015,
    2020,
    2025,
    2030,
    2035,
    2040,
    2045,
    2050
]



function AreaChart({ width, height, data, minimap, roi }) {
    const yScale = scaleLinear({
        domain: [0, 100000],
        nice: true,
    })


    const xScale = scaleOrdinal({
        domain: keys,
        nice: true
    })
    const colors = [
        "rgba(62, 195, 206, 1)", "rgba(19, 33, 79, 0.6)"
    ]
    const colorScale = scaleOrdinal({
        domain: [0, 5],
        range: ["rgb(62, 195, 206)", "rgb(61, 136, 194)", "rgb(19, 33, 79)", "rgb(252, 223, 204)", "rgb(245, 170, 68)", "#C32F22", "#482621"],
    });

    let xMax = width - margin.left - margin.right;
    let yMax = height - margin.top - margin.bottom;
    const range = []

    if (width < 600) {

        yMax = height - margin.bottom - margin.top - 40;
    }
    for (let i = 0; i < keys.length; i++) {
        range.push(i * xMax / keys.length)
    }
    xScale.range(range)

    yScale.range([yMax, 0])
    return (
        <div css={BarStyles}>
            { width > 10 &&
                <svg width={width} height={height}>
                    <rect width={width} height={height} fill={"transparent"} rx={14} />
                    <pattern id="diagonalHatch2" width="6" height="10" patternTransform="rotate(45 0 0)" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="10" style={{ stroke: "#fff", strokeWidth: 1 }} />
                    </pattern>
                    <Group top={margin.top + width < 600 ? + 80 : 10} left={margin.left}>
                        {data.map(({ data }, idx) => (
                            <AreaClosed
                                data={data}
                                x={d => xScale(getYear(d))}
                                y={d => yScale(getYearValue(d))}
                                yScale={yScale}
                                key={"area-chart-" + idx}
                                curve={curveNatural}
                            >
                                {({ path }) => (

                                    <motion.path
                                        d={path(data)}
                                        fill={colors[idx]}
                                        stroke={colorScale(idx)}
                                        strokeWidth={2}

                                    >

                                    </motion.path>

                                )}
                            </AreaClosed>
                        ))}
                        <AxisLeft
                            label="CO2 Emission"
                            hideTicks
                            scale={yScale}
                            numTicks={5}
                            stroke={"#121212"}
                            tickStroke={"#121212"}
                            tickLabelProps={() => ({
                                fill: "#121212",
                                fontSize: 13,
                                textAnchor: 'end',
                                dy: '0.33em',
                            })}
                            labelProps={{
                                fontSize: 14,
                                dy: '2.5em'
                            }}
                            labelOffset={50}
                        />
                        <AxisBottom
                            hideTicks
                            scale={xScale}
                            numTicks={10}
                            stroke={"#121212"}
                            tickStroke={"#121212"}
                            top={yMax + margin.top - 40}
                            hideZero={true}
                            left={0}
                            tickLabelProps={() => ({
                                fill: "#121212",
                                fontSize: 13,
                                textAnchor: 'end',
                                dx: '0.63em',
                            })}
                        />


                    </Group>
                </svg>
            }
            <div>
                {roi && 
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                >
                    <LineChart
                        data={minimap}
                    />
                </motion.div>
                }

            </div>
        </div >
    )
}





export function LineChart({ data }) {

    return (
        <ResponsiveLine
            data={data}
            margin={{ top: 20, right: 20, bottom: 20, left: 40 }}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 0, max: 40, stacked: false, reverse: false }}
            axisTop={null}
            axisRight={null}
            colors={["#3EC3CE", "#3D88C2", "#C32F22", "#482621"]}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Year",
                legendOffset: 36,
                legendPosition: 'middle'
            
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "ROI (£)",
                legendOffset: -33,
                legendPosition: 'middle',
                tickValues: 5
            }}
            pointSize={5}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            enableGridX={false}

            enableCrosshair={true}
            useMesh={false}

        />

    )
}


let currentStage = -1;




export default function GasReductionArea({ chart, paragraphs }) {
    const [stage, setStage] = useState(0)
    const [indexesToShow, setIndexesToShow] = useState(-1)
    const [ROI, showROI] = useState(false)
    const data = chart.data.filter((elem, idx) => idx <= stage)
    const properData = []

    data && data.forEach((datapoint, idx) => {
        if (idx === stage) {
            const data = {
                ...datapoint,
                data: datapoint.data.filter((elem, idx) => idx <= indexesToShow)
            }
            properData.push(data)
        } else {
            properData.push(datapoint)
        }

    })



    useEffect(() => {
        responsiveTrigger({
            obj: {
                trigger: "#Area-0",
                onToggle: ({ direction, isActive }) => {
                    if (isActive && direction === -1) {
                        setStage(0)
                    }
                },
                onUpdate: ({ progress }) => {
                    const value = progress * 9;
                    if (Math.round(value) !== currentStage) {
                        currentStage = Math.round(value)
                        setIndexesToShow(currentStage)
                    }
                },
            },
            desktop: {
                start: "top center+=200px",
                end: "center center",
            },
            mobile: {
                start: "center bottom",
                end: "center bottom",
            }
        })

        responsiveTrigger({
            obj: {
                trigger: "#Area-0",
                endTrigger: "#Area-1",
                onToggle: ({ isActive, direction }) => {
                    if (isActive && direction === 1) {
                        setIndexesToShow(-1)
                        setStage(1)
                    }
                },
                onUpdate: ({ progress }) => {
                    const value = progress * 9;
                    if (Math.round(value) !== currentStage) {
                        currentStage = Math.round(value)
                        setIndexesToShow(currentStage)
                    }
                },
            },
            desktop: {
                start: "bottom center",
                end: "top center",
            },
            mobile: {
                start: "center bottom",
                end: "center bottom"
            }
        });

        responsiveTrigger({
            obj: {
                trigger: "#Area-2",
                onToggle: ({ isActive, direction }) => {
                    if (isActive) {
                        showROI(true)
                    }
                    if (!isActive && direction === -1) {
                        showROI(false)
                    }
                },
            },
            desktop: {
                start: "top center",
                end: "top top",
            },
            mobile: {
                start: "center bottom",
                end: "bottom center"
            }
        })

    }, [])
    return (
        <ScrollyTeller>
            <ChartSection>
                <ParentSize>
                    {({ width, height, top, left, ref }) => (
                        <AreaChart
                            width={width}
                            height={height}
                            top={top}
                            left={left}
                            ref={ref}
                            data={properData}
                            minimap={chart.minimap}
                            roi={ROI}
                        />
                    )}
                </ParentSize>
                <span dangerouslySetInnerHTML={{ __html: chart.source }} />
            </ChartSection>
            <Commentary>
                {paragraphs.map((item, idx) => (
                    <p id={"Area-" + idx}>{item}</p>
                ))}
            </Commentary>

        </ScrollyTeller>
    )
}



