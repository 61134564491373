import React, { useEffect, useMemo, useState } from 'react'
import { Bar } from '@visx/shape';
import { ParentSize } from '@visx/responsive';
import { Group } from '@visx/group';
import { ChartSection, Commentary, ScrollyTeller } from '../ScrollyTeller';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { LegendOrdinal } from '@visx/legend';
import { css } from '@emotion/core';
import { motion } from "framer-motion"
import { responsiveTrigger, ScrollTrigger } from '../gsap';
import { useTooltip, Tooltip, defaultStyles } from '@visx/tooltip';
import { Annotation, Connector, CircleSubject, Label } from '@visx/annotation';



const margin = { top: 40, left: 80, right: 40, bottom: 50 };

const BarStyles = css`
    position: relative;
    > div {
        position: absolute;
        bottom: 64px;
        right: 16px;
    }
    > svg {
        
        rect {
            &.highlighted {
                stroke: #121212;
                /* stroke-width: 2px; */
                transition: fill 0.5s ease-in-out;
                /* fill: url(#diagonalHatch); */
            }
        }
    }
`


const getYear = (d) => d.year
const getYearValue = d => d.devices


export function BarChart({ width, height, data, risky }) {
    const keys = data.map(getYear)
    const xScale = scaleLinear({
        domain: [1000000, 200000000],
        nice: true,
    })

    const yScale = scaleBand({
        domain: keys,
        padding: 0.1,
    })

    const colorScale = scaleOrdinal({
        domain: keys,
        range: ["#3EC3CE", "#3D88C2", "#13214F", "#FCDFCC", "#F5AA44", "#C32F22", "#482621"],
    });

    let xMax = width - margin.left - margin.right;
    let yMax = height - margin.top - margin.bottom;
    if (width < 600) {
        xMax += margin.right
        yMax = height - margin.bottom - margin.top - 40;
    }
    xScale.rangeRound([0, xMax - 40])
    yScale.range([0, yMax])
    return (
        <div css={BarStyles}>
            { width > 10 &&
                <svg width={width} height={height}>
                    <rect width={width} height={height} fill={"transparent"} rx={14} />

                    <Group top={margin.top} left={margin.left}>
                        {data.map(d => {
                            const year = getYear(d);
                            const barWidth = xScale(getYearValue(d));
                            const barHeight = yScale.bandwidth();
                            const barX = 0;
                            const barY = yScale(year);
                            return (
                                <>
                                    <motion.rect
                                        key={`bar-${year}`}
                                        initial={{
                                            x: barX,
                                            y: barY,
                                            width: 0,
                                        }}
                                        animate={{
                                            x: barX,
                                            y: barY,
                                            width: barWidth,
                                        }}
                                        transition={{ duration: 0.5 }}

                                        height={barHeight}
                                        fill={colorScale(year)}
                                    />
                                    {year === "2024" && risky === true &&
                                        <>
                                            <motion.rect
                                                key={`barSpecial-${year}`}
                                                initial={{
                                                    x: barX,
                                                    y: barY,
                                                    width: 0,
                                                }}
                                                animate={{
                                                    x: barX,
                                                    y: barY,
                                                    width: barWidth * 0.8,
                                                }}
                                                transition={{ duration: 0.5 }}
                                                height={barHeight}
                                                fill={"url(#diagonalHatch2)"}
                                                stroke={"#121212"}
                                                strokeWidth="1px"
                                            />
                                            <motion.g
                                                initial={{
                                                    opacity: 0
                                                }}
                                                animate={{
                                                    opacity: 1
                                                }}
                                                transition={{ duration: 1 }}
                                            >
                                                <AnnotationComponent x={barWidth * 0.8 - 15} y={barY + 10} anchorStroke={colorScale(year)} />
                                            </motion.g>

                                        </>
                                    }
                                </>
                            )


                        })}
                        <AxisLeft
                            hideTicks
                            scale={yScale}
                            numTicks={20}
                            stroke={"#121212"}
                            tickStroke={"#121212"}
                            tickLabelProps={() => ({
                                fill: "#121212",
                                fontSize: 14,
                                textAnchor: 'end',
                                dy: '0.33em',
                            })}
                        />
                        <AxisBottom
                            label="Number of connected devices"
                            hideTicks
                            scale={xScale}
                            numTicks={5}
                            stroke={"#121212"}
                            tickStroke={"#121212"}
                            top={yMax + margin.top - 40}
                            tickLabelProps={() => ({
                                fill: "#121212",
                                fontSize: 13,
                                textAnchor: 'end',
                                dy: '0.0em',
                            })}
                            labelProps={{
                                fontSize: 14,
                                dx: '-4rem'
                            }}
                        />

                    </Group>
                </svg>
            }
            <div>

            </div>
        </div >
    )
}


function AnnotationComponent({ x, y, anchorStroke }) {
    return (
        <Annotation
            x={x}
            y={y}
            dy={-30}
            dx={30}
            key={`bar2-annotation-${x + y}`}
        >
            <Connector />
            <CircleSubject radius={1} />
            <Label
                anchorLineStroke={anchorStroke}
                title={"Risky connected devices"}
                backgroundFill="white"
                subtitle={"120 Million"}
                backgroundProps={{ stroke: anchorStroke }}
                subtitleFontSize={14}
                titleFontSize={14}
            />
        </Annotation>
    )
}




const generateChartData = (stage, data) => {
    // set everything to 0
    if (stage === 0) {
        return data.map(item => (
            {
                ...item,
                devices: 0
            }
        ));
    }
    // set first chart to value
    if (stage === 1) {
        return data.map((item) => (
            {
                ...item,
                devices: item.year === "2024P" ? 0 : item.devices
            }
        ))
    }

    if (stage === 2) {
        return data
    }
}
const current = { stage: 0 }

export default function UkIotBar({ chart, paragraphs }) {
    const [stage, setStage] = useState(current.stage)
    const [risky, setRisky] = useState(false)

    useEffect(() => {
        responsiveTrigger({
            obj: {
                trigger: "#Bar2-0",
                endTrigger: "#Bar2-1",
                onUpdate: ({ progress }) => {
                    const scaledValue = Math.round(progress.toFixed(2) * 1);
                    if (scaledValue + 1 !== stage) {
                        current.stage = scaledValue + 1
                        setStage(current.stage)
                    }
                },
                onToggle: ({ isActive, direction }) => {
                    if (!isActive && direction === -1)
                        setStage(0);

                }
            },
            desktop: {
                start: "top center",
                end: "top center",
            },
            mobile: {
                start: "center bottom",
                end: "center bottom"
            }
        })
        responsiveTrigger({
            obj: {
                trigger: "#Bar2-1",
                onToggle: ({ isActive, direction }) => {
                    if (isActive == true) {
                        setRisky(isActive)
                    } else if (direction === -1) {
                        setRisky(false)
                    }
    
                    if (!(current.stage === 2) && isActive) {
                        current.stage = 2;
                        setStage(2)
                    }
                }
            },
            desktop: {
                start: "center center",
                end: "top top",
            },
            mobile: {
                start: "center bottom",
                end: "center center"
            }
        })

    }, [])


    return (
        <ScrollyTeller>
            <ChartSection>
                <ParentSize>
                    {({ width, height, top, left, ref }) => (
                        <BarChart
                            width={width}
                            height={height}
                            top={top}
                            left={left}
                            ref={ref}
                            data={generateChartData(stage, chart.data)}
                            risky={risky}
                        />
                    )}
                </ParentSize>
                <span dangerouslySetInnerHTML={{ __html: chart.source}}/>
            </ChartSection>
            <Commentary>
                {paragraphs.map((item, idx) => (
                    <p id={"Bar2-" + idx}>{item}</p>
                ))}
            </Commentary>

        </ScrollyTeller>
    )
}