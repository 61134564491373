import LeadsInvestment from "../charts/LeadsInvestment";
import UkIot from "../charts/UkIot";
import UkCyberExports from "../charts/UkCyberExports";
import GasReduction from "../charts/GasReduction";
import UkFeatures from "../charts/geojson/uk-counties.json";
import * as topojson from 'topojson-client';
import FintechSubsectors from "../charts/FintechSubsectors";
import SmartCities from "../charts/geojson/SmartCities";
import UkCyberFirm from "../charts/UkCyberFirm";
import FintechMegadeals from "../charts/FintechMegadeals";
import { withPrefix } from "gatsby";
import EdtechInvestment from "../charts/EdtechInvestment";

export default {
    intro: {
        title: "Post Covid-19: UK’s tech sector set to boom",
        copy: "<p>Despite several major headwinds including Brexit and the covid-19 pandemic, the UK is flourishing. Venture capital (VC) fundraising has been on the up for several years, closing a record £15bn in 2020. Seven new unicorns were created last year, taking the total to 80 US$1bn tech companies born in the UK.</p>",
        img: ""
    },
    main: [

        {
            type: "scrollyteller",
            chart: {
                type: "bar-stacked",
                data: LeadsInvestment,
                source: `Source: <a href="https://technation.io/report2020/#11-global-investment-trends">Tech Nation</a>`
            },
            paragraphs: [
                "In terms of technology investment, London continues to lead of all European cities with a healthier growth trajectory than any of the other major hubs.",
                "In 2019 the UK capital raised nearly twice that by firms based in Berlin, the second-biggest target for investment. ",
                "Cambridge, Bristol, Dublin, Manchester and Oxford also feature on the list of European tech top spots."
            ]

        },

        {
            type: "header",
            title: "Cybersecurity",
            img: {
                src: withPrefix("/images/datastory/Group_5062.png")
            }
        },
        {
            type: "paragraphs",
            paragraphs: [
                "Just three years ago, 10% of people did not own a single connected device. Now, almost one in three of us use at least five, with the total number of devices connected through the internet of things (IoT) projected to grow to 150m by 2024 in the UK alone."
            ]
        },
        {
            type: "scrollyteller",
            chart: {
                type: "bar-horizontal",
                data: UkIot,
                source: `Source: <a href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/900327/Framing_the_nature_and_scale_of_cyber_security_vulnerabilities_within_the_current_consumer_internet_of_things__IoT__landscape.pdf">Centre for Strategy & Evaluation Services</a>`
            },
            paragraphs: [
                "Five years ago, there were 13 million devices in the UK, but as technology now touches all aspects of daily life, the number of connected devices is set to increase twelvefold by 2024. ",
                "It is expected that 80% of those connections will come from wearables, connected media, smart meters and emergency calling services, each introducing unique risks."
            ]

        },
        {
            type: "paragraphs",
            paragraphs: [
                "As the benefits of a digital revolution continue to emerge, so do its threats. Cyberattacks continue to evolve, and we are seeing ever-increasing levels of sophistication and frequency. Given the UK’s strong reputation for governance standards and its deep talent pool, its expertise is expected to be in demand for the foreseeable future, both domestically and internationally."
            ]
        },
        {
            type: "scrollyteller",
            chart: {
                type: "bar-vertical",
                data: UkCyberExports,
                source: `Source: <a href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/924315/UK-DEFENCE-AND-SECURITY-EXPORT-STATISTICS-FOR-2019-chart-14.csv/preview">Westlands Advisory and Frost & Sullivan</a>`
            },
            paragraphs: [
                "Demand for UK cybersecurity expertise is high and growing.",
                "The UK Government’s largest security subsector, UK cybersecurity exports increased by 90% since 2018.",
                "Meanwhile the number of companies operating in this space has grown by 44% since 2017, fuelling competition within the space."
            ]

        },
        {
            type: "scrollyteller",
            chart: {
                type: "sankey",
                data: UkCyberFirm,
                source: `Source: <a href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/861945/UK_Cyber_Sectoral_Analysis__2020__Report.pdf">DMCS</a>`
            },
            paragraphs: [
                "Cybersecurity is a sector rich with activity.",
                "We can see that 93% of cybersecurity firms from the 2017 baseline are still active.",
                "Elsewhere, 5% have dissolved, 1% of firms are in liquidation and a further 1% have been taken over or involved in a merger.",
                "There have been 118 new firms registered since 2017, which means that on average a new cybersecurity business is registered every week.",
                "That 320 additional firms have been identified suggests that companies are expanding their skillset to meet a growing need for cybersecurity."
            ]

        },
        {
            type: "header",
            title: "Smart cities",
            img: {
                src: withPrefix("/images/datastory/Group_5061.png")
            }
        },
        {
            type: "paragraphs",
            paragraphs: [
                "It could be said that the UK is a sleeping giant with regards to its smart-city potential.",
                "Smart cities use intelligent technology to enhance quality of life for urban citizens by addressing economic, social and environmental challenges. Smart ecosystems tend to span six key areas: energy, buildings, infrastructure, governance, mobility and health.",
                "Boasting 23 smart cities across three stages of evolution, the UK is also home to a number of specialist research facilities and postgraduate opportunities as it continues to nurture talent for future domestic benefit and overseas export."
            ]
        },
        {
            type: "scrollyteller",
            chart: {
                type: "map",
                data: SmartCities,
                features: topojson.feature(UkFeatures, UkFeatures.objects.GBR_adm2).features,
                source: `Source: DIT Smart Cities Proposition`
            },
            paragraphs: [
                "The UK promotes six locations as smart city leaders, whose more advanced status can offer testbeds for new technology and innovation.",
                "Eight cities are defined as developing, with smart ambitions at a slightly earlier stage.",
                "Our nine emerging smart cities present give planners in that sector a blank piece of paper to work from."
            ]

        },
        {
            type: "paragraphs",
            paragraphs: [
                "The UK is going further and faster than any other major economy to decarbonise transport by phasing out the sale of new petrol and diesel cars and vans by 2030. This is underpinned by more than £1.8bn to support greater uptake of zero-emission vehicles. Greener car journeys will improve air quality in the UK’s towns and cities and support economic growth right across the UK, potentially creating 40,000 extra jobs by 2030.",

            ]
        },
        {
            type: "scrollyteller",
            chart: {
                type: "area",
                data: GasReduction,
                source: `Source: <a href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/846593/future-of-mobility-strategy.pdf?utm_source=economist&utm_medium=display&utm_content=datastory-sources&utm_campaign=rtt">DfT</a>`,
                minimap: [
                    {
                        id: "Min",
                        data: [
                            {
                                "x": 2016,
                                "y": 1
                            },
                            {
                                "x": 2025,
                                "y": 8
                            },
                            {
                                "x": 2030,
                                "y": 20
                            }
                        ],

                    },
                    {
                        id: "Max",
                        data:[
                            {
                                "x": 2016,
                                "y": 1
                            },
                            {
                                "x": 2025,
                                "y": 14
                            },
                            {
                                "x": 2030,
                                "y": 34
                            }
                        ]
                    }
                ]

            },
            paragraphs: [
                "A look at CO2 road traffic emissions shows the potential impact of a zero-carbon strategy in England and Wales.",
                "Moving to zero emission vehicles in urban areas will prevent 80% of carbon emissions by 2050.",
                "This shift would also generate a projected return on investment of as high as £34 for every £1 by 2030."
            ]

        },
        {
            type: "header",
            title: "Fintech",
            img: {
                src: withPrefix("/images/datastory/Group_5060.png")
            }
        },
        {
            type: "paragraphs",
            paragraphs: [
                "The UK’s fintech scene is one of the most prominent globally, currently employing 76,500 people. That figure is set to reach 105,000 by 2030. ",
                "Despite the many challenges covid-19 has presented, investment in the fintech sector grew in the first six months of 2020 to US$1.84bn, up from US$1.5bn in the previous six-month period. London consistently attracts VC investment, leading other global cities by deal count in 2019 and ranking second only to San Francisco by deal value.",
                "According to a recent Innovate Finance report, the UK’s fintech sector has retained its role as the top-ranking investment destination in Europe, with US$4.1bn in VC and private equity growth. The UK attracted half of the total US$9.3bn invested in European fintech as a whole, garnering more deals and capital than Germany, Sweden, France, Switzerland and the Netherlands combined."
            ]
        },
        {
            type: "scrollyteller",
            chart: {
                type: "bubble",
                data: FintechMegadeals,
                source: `Source: <a href="https://www.innovatefinance.com/blogs/h1-2020-fintech-investment-positivity-in-the-pandemic-despite-drop-in-fundraising/">Innovate Finance</a> & <a href="https://technation.io/report2020/#12-uk-investment">Tech Nation</a>`

            },
            paragraphs: [
                "Fintech is one sector that has thrived in the face of the pandemic as companies look to futureproof their operations.",
                "Four ‘mega deals’ – rounds worth more than US$100m – comprised nearly half of total capital raised by the sector in H1 2020. ",

            ]

        },
        {
            type: "paragraphs",
            paragraphs: [
                "The breadth of opportunity is continually expanding, supported by regulatory reform such as Open Banking—which provides consumers and businesses with more control over their financial data—and the City watchdog’s increased interest in digital innovation.",
                "Further, the UK’s lead position on the world stage in Open Finance—extending Open Banking into other financial subsectors—was cemented with last year’s creation of the Edinburgh-based Global Open Finance Centre of Excellence, facilitated through funding by UK Research and Innovation."

            ]
        },
        {
            type: "scrollyteller",
            chart: {
                type: "lollipop",
                data: FintechSubsectors,
                source: `Source: <a href="https://assets.ey.com/content/dam/ey-sites/ey-com/en_gl/topics/emeia-financial-services/ey-uk-fintech-2020-report.pdf">EY</a>`
            },
            paragraphs: [
                "Fintech’s reach is growing from the fledgling to the established subsectors as companies recognise the benefits of digitisation.",
                "The more mature areas of the market such as software and payments are, understandably, more widely represented. ",
                "The more novel areas of the market, such as cryptocurrency and online broking have further to go."
            ]

        },
        {
            type: "header",
            title: "Edtech",
            img: {
                src: withPrefix("/images/datastory/Group_5059.png")
            }
        },
        {
            type: "paragraphs",
            paragraphs: [
                "Every year, British educational expertise is exported around the world, with China, Asia and the Middle East representing some of the biggest growth potential for this market—particularly in the edtech space. Edtech exports alone are said to be worth £170m to the UK economy.",
                "One unintended consequence of the covid-19 pandemic has been more digital teaching, training and learning, causing many traditional educational facilities to pivot and accelerating the relevance of edtech. ",
                "Among the fastest-growing sectors of the UK economy, edtech is forecast to be worth £3.4bn in 2021.",
                "Today, the UK is home to roughly 1,000 edtech businesses—and that figure is only increasing. London boasts the only edtech US$1bn unicorn in Europe, and the UK has attracted more VC investment since 2014 than France, Ireland and Germany combined."
            ]
        },
        {
            type: "scrollyteller",
            chart: {
                type: "line",
                data: EdtechInvestment,
                source: `Source: <a href="https://blog.dealroom.co/wp-content/uploads/2020/09/EdTech-vFINAL.pdf">Dealroom.co </a>`
            },
            paragraphs: [
                "The UK leads Europe’s edtech sector by investment, attracting more than US$1bn since 2014.",
                "Compared with its nearest European peers, the UK continually outpaces France, Germany and neighbouring Ireland, having received 39% of all European edtech investment in 2019.",
            ]

        },
        {
            type: "header",
            title: "Conclusion",
            copy: `As the digital revolution picks up the pace, the UK’s bold ambitions across increasingly essential industry sectors are supported by continued investment from corporates and government, world-class academic institutions and a hive of British talent. With technology transforming businesses from all sectors, there is a growing opportunity to showcase the UK’s sustainable credentials, creativity and innovation, and modern, trustworthy governance standards. <br/><br/>
            To find out more, visit <a href="https://www.great.gov.uk/international/">https://www.great.gov.uk/international/</a>`,
            img: "",
            extend: true
        },
        {
            type: "paragraphs",
            paragraphs: [
                "Produced for DIT by (E) BrandConnect, a commercial division of The Economist Group, which operates separately from the editorial staffs of The Economist and The Economist Intelligence Unit. Neither (E) BrandConnect nor its affiliates accept any responsibility or liability for reliance by any party on this content."
            ],
            style: { fontSize: "16px" }
        },

    ],
    sliderSection: {
        headerImgPath: 'images/innovation-is-great.png',
        headerImgAlt: 'Innovation is great img alt',
        settings: {
            dots: false,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 3.2,
                        slidesToScroll: 1,
                        draggable: true,
                    }
                }, {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2.2,
                        slidesToScroll: 1,
                    }
                }, {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1.2,
                        slidesToScroll: 1,
                        draggable: true,
                        arrows: true
                    }
                }
            ]
        },
        items: [
            {
                img: 'images/homepage_fintech.jpg',
                title: 'Powering fintech innovation',
                text: 'How fintech groups like Flux are taking their innovations global.',
                more: {
                    label: 'Read more',
                    link: '/story/fintech/'
                }
            }, {
                img: 'images/home_smart_cities.jpg',
                title: 'The net-zero cities',
                text: 'From smart buildings to electric vehicles, UK companies are spearheading the clean-tech revolution',
                more: {
                    label: 'Read more',
                    link: '/story/smart_cities/'
                }
            }, {
                img: 'images/home_edtech.jpg',
                title: 'Inspiring innovative minds with edtech',
                text: 'How Twig Education is reimagining science education on a global scale.',
                more: {
                    label: 'Read more',
                    link: '/story/edtech/'
                }
            }, {
                img: 'images/home_cybersecurity.jpg',
                title: 'Securing digital identities',
                text: 'A look at Amiqus and other UK startups at the vanguard of cybersecurity.',
                more: {
                    label: 'Read more',
                    link: '/story/cybersecurity/'
                }
            }
        ]
    },
}
