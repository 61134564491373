import React, { useEffect} from 'react'
import { ChartSection, Commentary, ScrollyTeller } from '../ScrollyTeller';

import { css } from '@emotion/core';
import { responsiveTrigger } from '../gsap';
import { ResponsiveSankey } from '@nivo/sankey';



const margin = { top: 40, left: 80, right: 40, bottom: 40 };

const BarStyles = css`
    position: relative;
    > div {
        position: absolute;
        bottom: 64px;
        right: 16px;
    }
    > svg {
        
        rect {
            &.highlighted {
                stroke: #121212;
                /* stroke-width: 2px; */
                transition: fill 0.5s ease-in-out;
                /* fill: url(#diagonalHatch); */
            }
        }
    }
`

export function SankeyChart({ width, height, data, risky }) {
    return (
        <ResponsiveSankey
            data={data}
            margin={{ top: 40, right: 20, bottom: 40, left: 20 }}
            align="justify"
            colors={["#3EC3CE", "#3D88C2", "#13214F", "#FCDFCC", "#F5AA44", "#C32F22", "#f57756"]}
            nodeOpacity={1}
            nodeThickness={18}
            nodeInnerPadding={3}
            nodeSpacing={24}
            nodeBorderWidth={0}
            nodeBorderColor={{ from: 'color', modifiers: [['brighter', 3]] }}
            linkOpacity={0.5}
            linkHoverOthersOpacity={0.1}
            enableLinkGradient={true}
            labelPosition="inside"
            labelOrientation="horizontal"
            isInteractive={false}
            labelPadding={16}
            labelTextColor={"#121212"}
            theme={{

                    fontSize: '16'
            }}
        />
    )
}





export default function Sankey({ chart, paragraphs }) {
    useEffect(() => {
        // highlight all base line 
        responsiveTrigger({
            obj: {
                trigger: "#sankey-0",
                endTrigger: "#sankey-1",
                onToggle: ({ isActive, direction }) => {
                    if (isActive) {
                        const nodes = [...document.querySelectorAll("#sankey path")];
                        nodes.forEach(node => {
                            if (node.attributes.getNamedItem('fill').value.startsWith(`url("#Baseline`)) {
                                node.animate([{ fillOpacity: 0.8 }], { duration: 500, fill: "forwards" })
                            } else {
                                node.animate([{ fillOpacity: 0.1 }], { duration: 500, fill: "forwards" })
                            }
                        })
                    }
                    if (!isActive && direction === -1) {
                        const nodes = [...document.querySelectorAll("#sankey path")];
                        nodes.forEach(node => {
                            if (node.attributes.getNamedItem('fill').value.startsWith(`url("#Baseline`)) {
                                node.animate([{ fillOpacity: 0.5 }], { duration: 500, fill: "forwards" })
                            } else {
                                node.animate([{ fillOpacity: 0.5 }], { duration: 500, fill: "forwards" })
                            }
                        })
                    }
                },
            },
            desktop: {
                start: "top center+=100px",
                end: "top center",
            },
            mobile: {
                start: "center bottom",
                end: "center bottom"
            }
        });


        responsiveTrigger({
            obj: {
                trigger: "#sankey-1",
                endTrigger: "#sankey-2",
                onToggle: ({ isActive, direction }) => {
                    if (isActive) {
                        const nodes = [...document.querySelectorAll("#sankey path")];
                        nodes.forEach(node => {
                            if (node.attributes.getNamedItem('fill').value.startsWith(`url("#Baseline.Still`)) {
                                node.animate([{ fillOpacity: 0.8 }], { duration: 500, fill: "forwards" })
                            } else {
                                node.animate([{ fillOpacity: 0.1 }], { duration: 500, fill: "forwards" })
                            }
                        })
                    }


                },
            },
            desktop: {
                start: "top center+=100px",
                end: "top center",
            },
            mobile: {
                start: "center bottom",
                end: "center bottom"
            }
        });

        responsiveTrigger({
            obj: {
                trigger: "#sankey-2",
                endTrigger: "#sankey-3",
                onToggle: ({ isActive, direction }) => {
                    if (isActive) {
                        const nodes = [...document.querySelectorAll("#sankey path")];
                        nodes.forEach(node => {
                            if (node.attributes.getNamedItem('fill').value.startsWith(`url("#Baseline`)) {
                                if (node.attributes.getNamedItem('fill').value.startsWith(`url("#Baseline.Still`)) {
                                    node.animate([{ fillOpacity: 0.1 }], { duration: 500, fill: "forwards" })
                                } else {
                                    node.animate([{ fillOpacity: 0.8 }], { duration: 500, fill: "forwards" })
                                }

                            } else {
                                node.animate([{ fillOpacity: 0.1 }], { duration: 500, fill: "forwards" })
                            }
                        })
                    }

                },
            },
            desktop: {
                start: "top center+=100px",
                end: "top center",
            },
            mobile: {
                start: "center bottom",
                end: "center bottom"
            }
        })
        responsiveTrigger({
            obj: {
                trigger: "#sankey-3",
                onToggle: ({ isActive, direction }) => {
                    if (isActive) {
                        const nodes = [...document.querySelectorAll("#sankey path")];
                        nodes.forEach(node => {
                            if (node.attributes.getNamedItem('fill').value.startsWith(`url("#Newly`)) {
                                node.animate([{ fillOpacity: 0.8 }], { duration: 500, fill: "forwards" })
                            } else {
                                node.animate([{ fillOpacity: 0.1 }], { duration: 500, fill: "forwards" })
                            }
                        })
                    }

                },
            },
            desktop: {
                start: "top center+=100px",
                end: "top top",
            },
            mobile: {
                start: "center bottom",
                end: "center center"
            }
        });
        responsiveTrigger({
            obj: {
                trigger: "#sankey-4",
                onToggle: ({ isActive, direction }) => {
                    if (isActive && direction === 1) {
                        const nodes = [...document.querySelectorAll("#sankey path")];
                        nodes.forEach(node => {
                            if (node.attributes.getNamedItem('fill').value.startsWith(`url("#Addition`)) {
                                node.animate([{ fillOpacity: 0.8 }], { duration: 500, fill: "forwards" })
                            } else {
                                node.animate([{ fillOpacity: 0.1 }], { duration: 500, fill: "forwards" })
                            }
                        })
                    }

                },
            },
            desktop: {
                start: "top center+=100px",
                end: "top top",
            },
            mobile: {
                start: "center bottom",
                end: "center center"
            }
        })


    }, [])

    return (
        <ScrollyTeller>
            <ChartSection id={"sankey"}>
                <SankeyChart
                    data={chart.data}
                />
                <span dangerouslySetInnerHTML={{ __html: chart.source}}/>
            </ChartSection>
            <Commentary>
                {paragraphs.map((item, idx) => (
                    <p id={"sankey-" + idx}>{item}</p>
                ))}
            </Commentary>

        </ScrollyTeller>
    )
}