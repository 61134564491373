export default [

    {
        "sector": "Trade finance and supply chain solutions",
        "representation": 2
    },
    {
        "sector": "Lending consumer financing",
        "representation": 2
    },
    {
        "sector": "Online aggregator/broker",
        "representation": 2
    },
    {
        "sector": "Cryptocurrency",
        "representation": 2
    },
    {
        "sector": "Digital wallet and prepaid cards",
        "representation": 3
    },
    {
        "sector": "Credit reference data and scoring",
        "representation": 3
    },
    {
        "sector": "Capital markets data and technology",
        "representation": 3
    },
    {
        "sector": "AI and machine learning",
        "representation": 4
    },
    {
        "sector": "Insurtech",
        "representation": 4
    },
    {
        "sector": "Asset management solutions",
        "representation": 4
    },
    {
        "sector": "Personal finance management",
        "representation": 4
    },
    {
        "sector": "Blockchain/DL solutions",
        "representation": 4
    },
    {
        "sector": "Analytics and big data",
        "representation": 5
    },
    {
        "sector": "Regtech and digital ID",
        "representation": 7
    },
    {
        "sector": "Online investments",
        "representation": 7
    },
    {
        "sector": "Payments and remittance",
        "representation": 8
    },
    {
        "sector": "Digital banking",
        "representation": 8
    },
    {
        "sector": "SMEs and corporate",
        "representation": 10
    },
    {
        "sector": "Financial software",
        "representation": 13
    }
]
