import React, { useEffect, useMemo, useState } from 'react'
import { Bar, Line } from '@visx/shape';
import { ParentSize } from '@visx/responsive';
import { Group } from '@visx/group';
import { ChartSection, Commentary, ScrollyTeller } from '../ScrollyTeller';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { LegendOrdinal } from '@visx/legend';
import { css } from '@emotion/core';
import { motion } from "framer-motion"
import { responsiveTrigger, ScrollTrigger } from '../gsap';



const margin = { top: 40, left: 200, right: 40, bottom: 40 };

const BarStyles = css`
    position: relative;
    > div {
        position: absolute;
        bottom: 64px;
        right: 16px;
    }

`


const getSector = (d) => d.sector
const getSectorValue = d => d.representation


const keys = [
    "Trade finance and supply chain solutions",
    "Lending consumer financing",
    "Online aggregator/broker",
    "Cryptocurrency",
    "Digital wallet and prepaid cards",
    "Credit reference data and scoring",
    "Capital markets data and technology",
    "AI and machine learning",
    "Insurtech",
    "Asset management solutions",
    "Personal finance management",
    "Blockchain/DL solutions",
    "Analytics and big data",
    "Regtech and digital ID",
    "Online investments",
    "Payments and remittance",
    "Digital banking",
    "SMEs and corporate",
    "Financial software"
]


export function Lollipop({ width, height, data, highlighted }) {

    const xScale = scaleLinear({
        domain: [0, 25],
        nice: true,
    })

    const yScale = scaleBand({
        domain: keys,
        padding: 0.1,
    })

    const colorScale = scaleOrdinal({
        domain: [0, 80],
        range: ["#3EC3CE", "#3D88C2", "#FCDFCC", "#F5AA44", "#C32F22", "#482621"],
    });


    let xMax = width - margin.left - margin.right;
    let yMax = height - margin.top - margin.bottom;

    if (width < 600) {
        xMax += margin.right - 16 + 40;
        yMax -= margin.top
    }

    xScale.rangeRound([0, xMax - 40])
    yScale.range([0, yMax])

    return (
        <div css={BarStyles}>
            { width > 10 &&
                <svg width={width} height={height}>
                    <rect width={width} height={height} fill={"transparent"} rx={14} />

                    <Group top={margin.top} left={margin.left}>
                        {data.map((d, idx) => {
                            const year = getSector(d);
                            const toX = xScale(getSectorValue(d));
                            const fromY = yScale(year) + yScale.bandwidth() / 2;
                            const fromX = 0;

                            const isHighlighted = highlighted.includes(year)
                            return (
                                <React.Fragment
                                    key={"unique-lollipop" + idx}
                                >
                                    <motion.line
                                        key={"unique-lollipop" + idx}
                                        strokeWidth={4}
                                        x1={fromX}
                                        x2={toX}
                                        initial={{
                                            x2: fromX,
                                            stroke: "#121212"
                                        }}
                                        animate={{
                                            x2: toX,
                                            stroke: isHighlighted ? "#3EC3CE" : "#121212"
                                        }}
                                        y1={fromY}
                                        y2={fromY}
                                    />
                                    <motion.circle
                                        cy={fromY}
                                        initial={{
                                            cx: fromX,
                                            fill: "#121212"
                                        }}
                                        animate={{
                                            cx: toX,
                                            fill: isHighlighted ? "#3EC3CE" : "#121212"
                                        }}
                                        r={6}
                                    />


                                </React.Fragment>
                            )


                        })}
                        <AxisLeft
                            hideTicks
                            scale={yScale}
                            numTicks={20}
                            stroke={"#121212"}
                            tickStroke={"#121212"}
                            tickLabelProps={() => ({
                                fill: "#121212",
                                fontSize: 11,
                                textAnchor: 'end',
                                dy: '0.33em',
                            })}
                        />
                        <AxisBottom
                            label="% representation"
                            hideTicks
                            scale={xScale}
                            numTicks={5}
                            stroke={"#121212"}
                            tickStroke={"#121212"}
                            top={yMax + margin.top - 40}
                        />

                    </Group>
                </svg>
            }
            <div>

            </div>
        </div >
    )
}


let current = 0

export default function FintechSubsectors({ chart, paragraphs }) {
    const data = chart.data
    const [indexesToShow, setIndexesToShow] = useState(0);
    const [highlighted, setHighlighted] = useState([""])
    useEffect(() => {

        responsiveTrigger({
            obj: {
                trigger: "#fintech-0",
                endTrigger: "#fintech-1",

                onToggle: ({ isActive }) => {
                    if (isActive) {
                        setHighlighted([""])
                    }
                },
                onUpdate: ({ progress }) => {
                    const value = progress * data.length;
                    if (Math.round(value) !== current) {
                        current = Math.round(value)
                        setIndexesToShow(current)
                    }
                },
            },
            desktop: {
                start: "top center+=100px",
                end: "top center",
            },
            mobile: {
                start: "center bottom",
                end: "top bottom",
            }
        });

        responsiveTrigger({
            obj: {
                trigger: "#fintech-1",
                endTrigger: "#fintech-2",
                onToggle: ({ isActive }) => {
                    if (isActive) {
                        setHighlighted(["Financial software", "Payments and remittance"])
                    }
                }
            },

            desktop: {
                start: "center center",
                end: "top center",
            },
            mobile: {
                start: "center bottom",
                end: "center bottom",
            }
        });

        responsiveTrigger({
            obj: {
                trigger: "#fintech-2",
      
                onToggle: ({ isActive }) => {
                    if (isActive) {
                        setHighlighted(["Online aggregator/broker", "Cryptocurrency",])
                    }
                }
            },
            desktop: {
                start: "top center",
                end: "bottom center-=100px",
            },
            mobile: {
                start: "center bottom",
                end: "bottom center+=100px",
            }
        })


    }, [])
    return (
        <ScrollyTeller>
            <ChartSection>
                <ParentSize>
                    {({ width, height, top, left, ref }) => (
                        <Lollipop
                            width={width}
                            height={height}
                            top={top}
                            left={left}
                            ref={ref}
                            data={data.slice(0, indexesToShow + 1)}
                            highlighted={highlighted}
                        />
                    )}
                </ParentSize>
                <span dangerouslySetInnerHTML={{ __html: chart.source }} />
            </ChartSection>
            <Commentary>
                {paragraphs.map((item, idx) => (
                    <p id={"fintech-" + idx}>{item}</p>
                ))}
            </Commentary>

        </ScrollyTeller>
    )
}