import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


const responsiveTrigger = ({ obj, desktop, mobile }) => {
    ScrollTrigger.matchMedia({
        "(max-width: 900px)": () => {
            let scroller = ScrollTrigger.create({
                ...obj,
                start: mobile.start,
                end: mobile.end
            })

            return () => {
                scroller.kill();
            }
        },
        "(min-width: 900px)": () => {
            let scroller = ScrollTrigger.create({
                ...obj,
                start: desktop.start,
                end: desktop.end
            })

            return () => {
                scroller.kill();
            }
        }
    });
}


export { 
    gsap,
    ScrollTrigger,
    responsiveTrigger
}


