import React, { useEffect, useMemo, useState } from 'react'
import { Bar } from '@visx/shape';
import { ParentSize } from '@visx/responsive';
import { Group } from '@visx/group';
import { ChartSection, Commentary, ScrollyTeller } from '../ScrollyTeller';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { LegendOrdinal } from '@visx/legend';
import { css } from '@emotion/core';
import { motion } from "framer-motion"
import { responsiveTrigger, ScrollTrigger } from '../gsap';




const margin = { top: 40, left: 80, right: 40, bottom: 40 };

const BarStyles = css`
    position: relative;
    > div {
        position: absolute;
        bottom: 64px;
        right: 16px;
    }
    > svg {
        
        rect {
            &.highlighted {
                stroke: #121212;
                /* stroke-width: 2px; */
                transition: fill 0.5s ease-in-out;
                /* fill: url(#diagonalHatch); */
            }
        }
    }
`


const getYear = (d) => d.year
const getYearValue = d => d.value
const maxValue = (d) => Math.max(...d.map(getYearValue))


export function BarChart({ width, height, data, year }) {

    const keys = data.map(getYear)
    const yScale = scaleLinear({
        domain: [0, 5],
        nice: true,
    })


    const xScale = scaleBand({
        domain: keys,
        padding: 0.1,
    })

    const colorScale = scaleOrdinal({
        domain: keys,
        range: ["#3EC3CE", "#3D88C2", "#13214F", "#FCDFCC", "#F5AA44", "#C32F22", "#482621"],
    });

    
    let xMax = width - margin.left - margin.right;
    let yMax = height - margin.top - margin.bottom;
    if (width < 600) {
        xMax += margin.right
        yMax = height - margin.bottom - margin.top - 40;
    }
    xScale.rangeRound([0, xMax - 40])

    yScale.range([yMax, 0])

    return (
        <div css={BarStyles}>
            { width > 10 &&
                <svg width={width} height={height}>
                    <rect width={width} height={height} fill={"transparent"} rx={14} />
                    <pattern id="diagonalHatch2" width="6" height="10" patternTransform="rotate(45 0 0)" patternUnits="userSpaceOnUse">
                        <line x1="0" y1="0" x2="0" y2="10" style={{ stroke: "#fff", strokeWidth: 1 }} />
                    </pattern>
                    <Group top={margin.top} left={margin.left}>
                        {data.map(d => {
                            const currYear = getYear(d);
                            const barWidth = xScale.bandwidth();
                            const barHeight = yMax - yScale(getYearValue(d));
                            const barX = xScale(currYear);
                            const barY = yMax - barHeight;
                            return (
                                <>
                                    <motion.rect
                                        key={`bar-${currYear}`}
                                        initial={{
                                            x: barX,
                                            y: barY,
                                            height: 0
                                        }}
                                        animate={{
                                            x: barX,
                                            y: barY,
                                            height: currYear < year ? barHeight : 0
                                        }}
                                        transition={{ duration: 0.5 }}
                                        width={barWidth}
                                        fill={colorScale(currYear)}
                                    />

                                </>
                            )


                        })}
                        <AxisLeft
                            label="Exports in £bn"
                            hideTicks
                            scale={yScale}
                            numTicks={10}
                            stroke={"#121212"}
                            tickStroke={"#121212"}
                            tickLabelProps={() => ({
                                fill: "#121212",
                                fontSize: 13,
                                textAnchor: 'end',
                                dy: '0.2em',
                            })}
                        />
                        <AxisBottom

                            hideTicks
                            scale={xScale}
                            numTicks={10}
                            stroke={"#121212"}
                            tickStroke={"#121212"}
                            top={yMax + margin.top - 40}
                            tickLabelProps={() => ({
                                fill: "#121212",
                                fontSize: 13,
                                textAnchor: 'end',
                                dy: '0.0em',
                                dx: '0.33em'
                            })}
                        />

                    </Group>
                </svg>
            }
            <div>

            </div>
        </div >
    )
}





const generateChartData = (year, data) => {
    // set everything to 0
    return data.filter(d => d.year < year)
}
const current = { year: 0 }

export default function UkCyberExportsBar({ chart, paragraphs }) {
    const [year, setYear] = useState(2016)
    useEffect(() => {

        responsiveTrigger({
            obj: {
                trigger: "#Bar3-0",
                endTrigger: "#Bar3-1",
                onUpdate: ({ progress }) => {
                    const scaledValue = Math.round(progress.toFixed(2) * 3);
                    if (current.year !== scaledValue + 2016) {
                        current.year = scaledValue + 2016
                        setYear(scaledValue + 2016)
                    }
                },
            },
            desktop: {
                start: "top center+=100px",
                end: "top center",
            },
            mobile: {
                start: "center bottom",
                end: "center bottom"
            }
        });

        responsiveTrigger({
            obj: {
                trigger: "#Bar3-2",
                onToggle: ({ isActive, direction }) => {
                    if (isActive)
                        current.year = 2020
                    setYear(2020)
                    if (!isActive && direction === -1) {
                        setYear(2019)
                        current.year = 2019
                    }
                }
            },
            desktop: {
                start: "top center",
                end: "top top",
            },
            mobile: {
                start: "center bottom",
                end: "center center"
            }
        })
    }, [])


    return (
        <ScrollyTeller>
            <ChartSection>
                <ParentSize>
                    {({ width, height, top, left, ref }) => (
                        <BarChart
                            width={width}
                            height={height}
                            top={top}
                            left={left}
                            ref={ref}
                            data={chart.data}
                            year={year}

                        />
                    )}
                </ParentSize>
                <span dangerouslySetInnerHTML={{ __html: chart.source}}/>
            </ChartSection>
            <Commentary>
                {paragraphs.map((item, idx) => (
                    <p id={"Bar3-" + idx}>{item}</p>
                ))}
            </Commentary>

        </ScrollyTeller>
    )
}