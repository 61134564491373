export default {
    "nodes": [
        {
            "id": "Baseline"
        },
        {
            "id": "Still Active"
        },
        {
            "id": "Active Firms"
        },
        {
            "id": "Dissolved"
        },
        {
            "id": "No longer active"
        },
        {
            "id": "Newly registered"
        },
        {
            "id": "Additional firms identified"
        },
        {
            "id": "In liquidation"
        },
    ],
    "links":[
        {
          "source": "Baseline",
          "target": "Still Active",
          "value": 783
        },
        {
          "source": "Baseline",
          "target": "Dissolved",
          "value": 46
        },
        {
          "source": "Baseline",
          "target": "In liquidation",
          "value": 9
        },
        {
          "source": "Baseline",
          "target": "No longer active",
          "value": 8
        },
        {
          "source": "Still Active",
          "target": "Active Firms",
          "value": 783
        },
        {
          "source": "Newly registered",
          "target": "Active Firms",
          "value": 118
        },
        {
          "source": "Additional firms identified",
          "target": "Active Firms",
          "value": 320
        }
      ]
}