export default [
    {
        id: "UK",
        data: [
            {
                "x": 2017,
                "y": 160
            },
            {
                "x": 2018,
                "y": 196
            },
            {
                "x": 2019,
                "y": 365
            },
            {
                "x": "2020*",
                "y": 53
            }
        ]
    },
    {
        id: "France",
        data: [
            {
                "x": 2017,
                "y": 34
            },
            {
                "x": 2018,
                "y": 183
            },
            {
                "x": 2019,
                "y": 122
            },
            {
                "x": "2020*",
                "y": 55
            }
        ]
    },
    {
        id: "Germany",
        data: [
            {
                "x": 2017,
                "y": 15
            },
            {
                "x": 2018,
                "y": 50
            },
            {
                "x": 2019,
                "y": 75
            },
            {
                "x": "2020*",
                "y": 18
            }
        ]
    },
    {
        id: "Ireland",
        data: [
            {
                "x": 2017,
                "y": 42
            },
            {
                "x": 2018,
                "y": 66
            },
            {
                "x": 2019,
                "y": 41
            },
            {
                "x": "2020*",
                "y": 8
            }
        ]
    }
]