import React, { useEffect, useMemo, useState } from 'react'
import { BarStackHorizontal } from '@visx/shape';
import { ParentSize } from '@visx/responsive';
import { Group } from '@visx/group';
import { ChartSection, Commentary, ScrollyTeller } from '../ScrollyTeller';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { LegendOrdinal } from '@visx/legend';
import { css } from '@emotion/core';
import { motion } from "framer-motion"
import { responsiveTrigger, ScrollTrigger } from '../gsap';
import { useTooltip, Tooltip, defaultStyles } from '@visx/tooltip';

const getCity = (d) => d.city;

const findTotals = (data, keys) => {
    return data.reduce((allTotals, currentDate) => {
        const totalTemperature = keys.reduce((dailyTotal, k) => {
            dailyTotal += Number(currentDate[k]);
            return dailyTotal;
        }, 0);
        allTotals.push(totalTemperature);
        return allTotals;
    }, []);
}


const getKeyAndData = (year, data) => {
    const keys = []
    for (let i = 2014; i <= year; i++) {
        keys.push(`${i}`)
    }

    const generatedData = data.map(item => {
        const obj = {}
        const keys = Object.keys(item)
        for (let key of keys) {
            if (Number(key) <= year || key === "city") {
                obj[key] = item[key]
            }
        }
        return obj
    })

    return [
        generatedData, keys
    ]
}

const margin = { top: 40, left: 85, right: 40, bottom: 40 };

const BarStyles = css`
    position: relative;
    > div {
        position: absolute;
        bottom: 64px;
        right: 16px;
    }
    @media(max-width: 600px){
        > div {
            bottom: 96px;
        }
    }
    > svg {
        
        rect {
            &.highlighted {
                stroke: #121212;
                /* stroke-width: 2px; */
                transition: fill 0.5s ease-in-out;
                /* fill: url(#diagonalHatch); */
            }
        }
    }
`


let tooltipTimeout;
export function Bar({ width, height, data, year, highlighted }) {



    const tooltip = useTooltip();
    const { showTooltip, hideTooltip } = tooltip;

    const [updated, keys] = getKeyAndData(year, data)
    const investmentTotal = findTotals(updated, keys)
    const investmentScale = scaleLinear({
        domain: [0, Math.max(...investmentTotal)],
        nice: true,
    })
    const axisBottomScale = scaleLinear({
        domain: [0, Math.max(...investmentTotal)],
        nice: true,
    })
    const cityScale = scaleBand({
        domain: data.map(getCity),
        padding: 0.1,
    })

    const colorScale = scaleOrdinal({
        domain: keys,
        range: ["#3EC3CE", "#3D88C2", "#13214F", "#FCDFCC", "#F5AA44", "#C32F22", "#482621"],
    });

    let xMax = width - margin.left - margin.right;
    let yMax = height - margin.top - margin.bottom;
    if (width < 600) {

        yMax = height - margin.bottom - margin.top - 40;
    }
    investmentScale.rangeRound([0, xMax / 6 * (year - 2013)])
    axisBottomScale.rangeRound([0, xMax])
    cityScale.range([0, yMax])
    return (
        <div css={BarStyles}>
            { width > 10 && <svg width={width} height={height}>
                <rect width={width} height={height} fill={"transparent"} rx={14} />
                <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
                    <path d="M-1,1 l2,-2
                                M0,4 l4,-4
                                M3,5 l2,-2"
                        style={{ stroke: "#fff", strokeWidth: 1 }} />
                </pattern>
                <Group top={margin.top} left={margin.left}>
                    <BarStackHorizontal
                        data={data}
                        keys={keys}
                        xScale={investmentScale}
                        yScale={cityScale}
                        color={colorScale}
                        height={yMax}

                        y={getCity}
                    >
                        {(barStacks) => (
                            barStacks.map(barStack => (
                                barStack.bars.map(bar => (
                                    <>

                                        <motion.rect
                                            key={`barstack-horizontal-${barStack.index}-${bar.index}-highlighted`}
                                            initial={{
                                                x: bar.x,
                                                y: bar.y,
                                                width: 0,
                                            }}
                                            animate={{
                                                x: bar.x,
                                                y: bar.y,
                                                width: bar.width,
                                            }}
                                            transition={{ duration: 0.5 }}
                                            height={bar.height}
                                            fill={bar.color}

                                        >
                                        </motion.rect>

                                        <motion.rect
                                            key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                                            initial={{
                                                x: bar.x,
                                                y: bar.y,
                                                width: 0,
                                                opacity: 0
                                            }}
                                            animate={{
                                                x: bar.x,
                                                y: bar.y,
                                                width: bar.width,
                                                opacity: highlighted.includes(bar.bar.data.city) ? 1 : 0
                                            }}
                                            className={highlighted.includes(bar.bar.data.city) ? "highlighted" : ""}
                                            transition={{ duration: 0.5 }}
                                            height={bar.height}
                                            fill={"url(#diagonalHatch)"}
                                            onClick={() => {
                                                if (tooltipTimeout) clearTimeout(tooltipTimeout);
                                                const top = bar.y + margin.top;
                                                const left = bar.x + bar.width + margin.left - 64;
                                                showTooltip({
                                                    tooltipData: bar,
                                                    tooltipTop: top,
                                                    tooltipLeft: left,
                                                });

                                                tooltipTimeout = window.setTimeout(() => {
                                                    hideTooltip();
                                                }, 1000);
                                            }}
                                        ></motion.rect>

                                    </>

                                ))
                            )

                            )
                        )}

                    </BarStackHorizontal>
                    <AxisLeft

                        hideTicks
                        scale={cityScale}
                        numTicks={20}
                        stroke={"#121212"}
                        tickStroke={"#121212"}
                        tickLabelProps={() => ({
                            fill: "#121212",
                            fontSize: 14,
                            textAnchor: 'end',
                            dy: '0.33em',
                        })}
                    />
                    <AxisBottom
                        label="Investment in £bn"
                        hideTicks
                        scale={axisBottomScale}
                        numTicks={5}
                        stroke={"#121212"}
                        tickStroke={"#121212"}
                        top={yMax + margin.top - 40}


                    />
                </Group>
            </svg>}
            <div>
                <LegendOrdinal scale={colorScale} direction="column" labelMargin="0 15px 0 0" />
            </div>
            <TooltipCustom tooltip={tooltip} colorScale={colorScale} />
        </div >
    )
}



function TooltipCustom({ colorScale, tooltip }) {
    const { tooltipOpen, tooltipData, tooltipTop, tooltipLeft } = tooltip;
    const tooltipStyles = {
        ...defaultStyles,
        minWidth: 60,
        height: 60,
        maxWidth: 100,
        backgroundColor: "#fff",
        color: '#121212',
    };
    return (
        <>
            {tooltipOpen && tooltipData && (
                <Tooltip top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
                    <div style={{ color: colorScale(tooltipData.key), marginBottom: "0.3rem", fontSize: 16 }}>
                        <strong>{tooltipData.key}</strong>
                    </div>
                    <div>£ {tooltipData.bar.data[tooltipData.key]}bn</div>
                    <div>
                        <small>{getCity(tooltipData.bar.data)}</small>
                    </div>
                </Tooltip>
            )}
        </>
    )
}




const current = { year: 2014 }

export default function ({ chart, paragraphs }) {
    const [year, setYear] = useState(2014)
    const [highlighted, setHighlighted] = useState([])
    useEffect(() => {

        responsiveTrigger({
            obj: {
                trigger: "#Bar0",
                endTrigger: "#Bar1",
                onUpdate: ({ progress }) => {
                    const scaledValue = Math.round(progress.toFixed(2) * 5);
                    if (current.year !== scaledValue + 2014) {
                        current.year = scaledValue + 2014
                        setYear(scaledValue + 2014)
                    }
                },
                onToggle: ({ isActive, direction }) => {
                    if (!isActive && direction === -1)
                        setHighlighted([])
                    if (isActive)
                        setHighlighted(["London"])
                },
            },
            desktop: {
                start: "center center",
                end: "top center",
            },
            mobile: {
                start: "center bottom",
                end: "center bottom",
            }
        })
        responsiveTrigger({
            obj: {
                trigger: "#Bar1",
                endTrigger: "#Bar2",
                onToggle: ({ isActive }) => {
                    if (isActive)
                        setHighlighted(["London", "Berlin"])
                }
            },
            desktop: {
                start: "center center",
                end: "top center",
            },
            mobile: {
                start: "center bottom",
                end: "center bottom",
            }
        });
        responsiveTrigger({
            obj: {
                trigger: "#Bar2",
                onToggle: ({ isActive }) => {
                    if (isActive)
                        setHighlighted(["Cambridge", "Bristol", "Dublin", "Manchester", "Oxford"])
                }
            }, 
            desktop: {
                start: "center center",
                end: "top top",
            },
            mobile: {
                start: "center bottom",
                end: "top center",
            }
        });


        return () => {

        }
    }, [])


    return (
        <ScrollyTeller>
            <ChartSection>
                <ParentSize>
                    {({ width, height, top, left, ref }) => (
                        <Bar
                            width={width}
                            height={height}
                            top={top}
                            left={left}
                            ref={ref}
                            data={chart.data}
                            year={year}
                            highlighted={highlighted}
                        />
                    )}
                </ParentSize>
                <span dangerouslySetInnerHTML={{ __html: chart.source}}/>
            </ChartSection>
            <Commentary>
                {paragraphs.map((item, idx) => (
                    <p id={"Bar" + idx}>{item}</p>
                ))}
            </Commentary>

        </ScrollyTeller>
    )
}


