export default [
    {
        "name": "root",
        "color": "#fff",
        "children": [
            {
                "name": "Fintech",
                "loc": "5.2"
            },
            {
                "name": "Deep Tech",
                "loc": "3.1"
            },
            {
                "name": "Healthtech",
                "loc": "2.1"
            },
            {
                "name": "Enterprise Software",
                "loc": "1.5"
            },
            {
                "name": "Foodtech",
                "loc": "0.9"
            },
            {
                "name": "Transportation tech",
                "loc": "0.5"
            }
        ]
    },
    {
        "name": "root",
        "color": "#fff",
        "children": [
            {
                "name": "Revolut",
                "loc": "500"
            },
            {
                "name": "Checkout.com",
                "loc": "150"
            },
            {
                "name": "Starling Bank",
                "loc": "123"
            },
            {
                "name": "Onfido",
                "loc": "100"
            }
        ]
    }
]
